import './App.css';

import { useCallback, useState } from 'react';

import { AccountsTab } from './AccountsTab';
import { AliasesTab } from './AliasesTab';
import { ForwardsTab } from './ForwardsTab';

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

var token = getParameterByName("token");
var tab = getParameterByName("tab");
var showTabs = getParameterByName("showTabs");
var apiHost = 'https://jriv58lfh8.execute-api.eu-west-1.amazonaws.com/dev/';

function App() {

  const [activeTab, setActiveTab] = useState(tab || 'accounts');
  const [actions, setActions] = useState([]);
  const [tabsVisible, setTabsVisible] = useState(showTabs ? true : false);

  const addActions = useCallback(
    (actions) => {
      setActions(actions)
    }
  , []);

  const headers = {
    'accounts' : 'Konton',
    'aliases' : 'Alias',
  }
  return (
    <div className="App">
      <h1>E-post</h1>
      <header className="App-header">
      {tabsVisible && (
        <div className="tabs">
          <div className={'tab ' + (activeTab === 'accounts' ? 'active' : '')}>
            <a href="#accounts" onClick={e => { setActions([]); setActiveTab('accounts') }}>E-postkonton</a>
          </div>
          <div className={'tab ' + (activeTab === 'aliases' ? 'active' : '')}>
            <a href="#aliases" onClick={e => { setActions([]); setActiveTab('aliases') }}>Alias</a>
          </div>
        </div>
        )}
        <div className="container">
          <div className='containerHeader'>
            <strong>{headers[activeTab]}</strong>
            <div className="actions">
              {actions.map((action, i) => {
                return(
                  <a key={i} href="#" className='actionButton' onClick={action.action}>{action.name}</a>
                );
              })}
            </div>
          </div>
          <div className="containerContent">
            {activeTab === 'accounts' && (<AccountsTab actionHandler={addActions} apiHost={apiHost} token={token}/>)}
            {activeTab === 'aliases' && (<AliasesTab actionHandler={addActions} apiHost={apiHost} token={token} />)}
            {activeTab === 'forwards' && (<ForwardsTab a actionHandler={addActions} piHost={apiHost} token={token} />)}
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
