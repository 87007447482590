import { useState, useEffect, useCallback } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';

function AccountsTab(props) {
  
  const { apiHost, token, actionHandler } = props;
  const [accounts, setAccounts] = useState([]);
  const [domains, setDomains] = useState([]);
  const [addAccountModalVisible, setAddAccountModalVisible] = useState(false);
  
  const [email, setEmail] = useState('');
  const [domain, setDomain] = useState('');
  const [password, setPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);

  const [accountToDelete, setAccountToDelete] = useState({});
  const [deleteAccountModalVisible, setDeleteAccountModalVisible] = useState(false);

  const [accountToEdit, setAccountToEdit] = useState({});
  const [editAccountModalVisible, setEditAccountModalVisible] = useState(false);
  
  const [loadingModalVisible, setLoadingModalVisible] = useState(false);

  const toHumanReadable = function(bytes) {
    if(bytes < 1) return("0B");
    var i = Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
  }

  const spam_scores = [
    {value : '0', name : 'Aggressivt'},
    {value : '1'},
    {value : '2'},
    {value : '3'},
    {value : '4'},
    {value : '5', name : 'Medel'},
    {value : '6'},
    {value : '8'},
    {value : '9'},
    {value : '10', name : 'Snällt'},
    {value : '99', name : 'Inaktiverat'},
  ]
  
  useEffect(() => {
    actionHandler([
      {
        name : 'Nytt konto +', 
        action : showAddAccountModal
      }
    ])
  }, [actionHandler]);

  const loadAccounts = useCallback(() => {
    fetch(`${apiHost}/mail/accounts`, {
      headers : {
        Authorization : token
      }
    })
    .then(res => res.json())
    .then((accounts) => {
      setAccounts(accounts);
    });
  }, [apiHost, token]);

  const loadDomains = useCallback(() => {
    fetch(`${apiHost}/mail/domains`, {
      headers : {
        Authorization : token
      }
    })
    .then(res => res.json())
    .then((domains) => {
      setDomains(domains);
      if(domains.length > 0)
        setDomain(domains[0].domain)
    });
  }, [apiHost, token]);

  useEffect(() => {
    loadAccounts();
    loadDomains();    
  }, [loadAccounts, loadDomains])

  const hideLoadingModal = () => { setLoadingModalVisible(false); }
  const showLoadingModal = () => { setLoadingModalVisible(true); }

  const reloadAccounts = () => {
    loadAccounts();
  }

  const setSpamScore = (account, spam_score) => {
    console.log(account, spam_score);
    showLoadingModal();
    fetch(`${apiHost}/mail/accounts/${account.id}`, {
      method : 'POST',
      body : JSON.stringify({ spam_score }),
      headers : {
        Authorization : token
      }
    })
    .catch((err) => {
      console.log(err);
      alert(err);
    })
    .then(res => res.json())
    .then(response => {
      console.log(response);
      hideLoadingModal();
      if(response.setSpamScoreReturn) {
        closeAddModal();
        reloadAccounts();
      } else {
        alert('Fel när adressen skulle skapas: ' + response);
      }
    })
  }

  /** create new account */

  const showAddAccountModal = () => {
    setPassword(''); 
    setEmail('');
    setPasswordStrength(0);
    setAddAccountModalVisible(true);
  }

  const closeAddModal = () => {
    setAddAccountModalVisible(false);
  }

  const saveNewAccount = () => {
    if(passwordStrength < 2) return;
    closeAddModal();
    showLoadingModal();
    fetch(`${apiHost}/mail/accounts`, {
      method : 'PUT',
      body : JSON.stringify(
        {
          email : `${email}@${domain}`, 
          password,
          domain
        }
      ),
      headers : {
        Authorization : token
      }
    })
    .then(res => res.json())
    .then(response => {
      hideLoadingModal();
      if(response.createNewAccountReturn) {
        closeAddModal();
        reloadAccounts();
      } else {
        alert('Fel när adressen skulle skapas: ' + response);
      }
    });    
  }

  /** edit */

  const showEditModal = (account) => {
    setAccountToEdit(account);
    setPassword(''); 
    setPasswordStrength(0);
    setEditAccountModalVisible(true); 
  }

  const closeEditModal = () =>{
    setEditAccountModalVisible(false);
  }

  const saveAccount = () => {
    if(passwordStrength < 2) return;
    closeEditModal();
    showLoadingModal();
    fetch(`${apiHost}/mail/accounts/${accountToEdit.id}`, {
      method : 'POST',
      body : JSON.stringify({ password }),
      headers : {
        Authorization : token
      }
    })
    .then(res => res.json())
    .then(response => {
      hideLoadingModal();
      if(response.setPasswordReturn) {
        closeEditModal();
      } else {
        alert('Fel när lösenordet skulle ändras: ' + response);
      }
    });  
  }

  /** delete */

  const showDeleteModal = (account) => {
    setAccountToDelete(account); 
    setDeleteAccountModalVisible(true);
  }

  const closeDeleteModal = () => {
    setDeleteAccountModalVisible(false);
  }

  const deleteAccount = () => {
    closeDeleteModal();
    showLoadingModal();
    fetch(`${apiHost}/mail/accounts/${accountToDelete.id}`, {
      method : 'DELETE',
      headers : {
        Authorization : token
      }
    })
    .then(res => res.json())
    .then(response => {
      hideLoadingModal();
      if(response.deleteAccountReturn) {
        closeDeleteModal();
        reloadAccounts();
      } else {
        alert('Fel när adressen skulle tas bort: ' + response);
      }
    });  
  }

  return (
    <div>
      {accounts.length === 0 && (
        <div className='listRow'>
          <div className='listCell'>
            Inga e-postkonton skapade ännu
          </div>
        </div>
      )}
      {accounts.length > 0 && (
        <div className='listRow'>
          <div className='listCell lc-20 listHead'>
            <strong>E-postadress</strong>
          </div>
          <div className='listCell lc-20 listHead'>
            <strong>Storlek</strong>
          </div>
          <div className='listCell lc-20 listHead'>
            <strong>Använt utrymme</strong>
          </div>
          <div className='listCell lc-20 listHead'>
            <strong>Spamfilternivå</strong>
          </div>
          <div className='listCell lc-20'>
            
          </div>
        </div>
      )}
      {accounts.map((account, i) => {
        return(
          <div className='listRow' key={i}>
            <div className='listCell lc-20'>
              {account.email}
            </div>
            <div className='listCell lc-20'>
              {account.quota === 0 ? 'Obegränsad' : (toHumanReadable(account.quota))}
            </div>
            <div className='listCell lc-20'>
              {toHumanReadable(account.quota_used)}
            </div>
            <div className='listCell lc-20'>
              <select onChange={e => setSpamScore(account, e.nativeEvent.target.value)} className='spam_score' value={account.spam_score}>
                {spam_scores.map((ss) => {
                  return(
                    <option key={ss.value} value={ss.value}>{ss.value} {ss.name ? (`(${ss.name})`) : ''}</option>
                  )
                })}
              </select>
            </div>
            <div className='listCell lc-20'>
              <div className='listActions'>
                <a href="#" className="blue" onClick={e => { showEditModal(account); }}>Byt lösenord</a>
                <a href="#" className="red" onClick={e => { showDeleteModal(account); }}>Ta bort</a>
              </div>
            </div>
          </div>
        )
      })}
      {addAccountModalVisible && (
        <div className="modal">
          <div className="modalContent">
            <label className='splitted'>
              E-postadress:
              <div style={{ display : 'flex', flexDirection : 'row', justifyContent : 'space-between'}}>
                <input type="email" value={email} onChange={e => { setEmail(e.nativeEvent.target.value); }} placeholder="mail" />
                <select value={domain} onChange={e => { setDomain(e.nativeEvent.target.value); }}>
                  {domains.map((domain, i) => {
                    return(<option key={i} value={domain.domain}>{domain.domain}</option>);
                  })}
                </select>
              </div>
            </label>
            <label>
              Lösenord:
              <input type="text" value={password} onChange={e => { setPassword(e.nativeEvent.target.value); }} placeholder="Säkert lösenord"></input>
              {password  && (<PasswordStrengthBar minLength={5} onChangeScore={setPasswordStrength} shortScoreWord={'för kort'} scoreWords={ ['svagt', 'svagt', 'ok', 'bra', 'säkert']} password={password} />)}
            </label>
            <div className='modalButtons'>
              <a href="#" onClick={closeAddModal}>Avbryt</a>
              <a href="#" onClick={saveNewAccount} disabled={passwordStrength < 2 || !domain}>Spara</a>
            </div>
          </div>
        </div>
      )}
      {editAccountModalVisible && (
        <div className="modal">
          <div className="modalContent">
            <label>
              Lösenord:
              <input type="text" value={password} onChange={e => { setPassword(e.nativeEvent.target.value); }} placeholder="Säkert lösenord"></input>
              {password  && (<PasswordStrengthBar minLength={5} onChangeScore={setPasswordStrength} shortScoreWord={'för kort'} scoreWords={ ['svagt', 'svagt', 'ok', 'bra', 'säkert']} password={password} />)}
            </label>
            <div className='modalButtons'>
              <a href="#" onClick={closeEditModal}>Avbryt</a>
              <a href="#" onClick={saveAccount} disabled={passwordStrength < 2}>Spara</a>
            </div>
          </div>
        </div>
      )}
      {deleteAccountModalVisible && (
        <div className="modal">
          <div className="modalContent">
            <p>Vill du verkligen ta bort kontot <strong>{accountToDelete.email}</strong></p>
            <div className='modalButtons'>
              <a href="#" onClick={closeDeleteModal}>Avbryt</a>
              <a href="#" onClick={deleteAccount}>Ta bort</a>
            </div>
          </div>
        </div>
      )}
      {loadingModalVisible && (
        <div className="modal">
          <div className="modalContent">
            <strong>Vänta ...</strong>
          </div>
        </div>
      )}
    </div>
  );
}


export { AccountsTab }