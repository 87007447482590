import { useState, useEffect } from 'react';

function ForwardsTab(props) {
  
  const { apiHost, token } = props;
  const [forwards, setForwards] = useState([]);

  useEffect(() => {
    fetch(`${apiHost}/mail/forwards`, {
      headers : {
        Authorization : token
      }
    })
    .then(res => res.json())
    .then((forwards) => {
      setForwards(forwards);
    });
  }, [apiHost, token])

  return (
    <table className="listTable" width="100%">
      <tbody>
        <tr>
          <th>E-postadress</th>
          <th>Skickas till</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
        {forwards.length === 0 && (
          <tr>
            <td colSpan="3">Inga vidarebefordringar skapade</td>
          </tr>
        )}
        {forwards.map((forward) => {
          return(
            <tr key={forward.id}>
              <td width="100%">{forward.email}</td>
              <td width="100%">{forward.dest}</td>
              <td>
                <a href="#">Redigera</a>
              </td>
              <td>
                <a href="#" >Ta bort</a>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  );
}


export { ForwardsTab }
