import { useState, useEffect, useCallback } from 'react';

function AliasesTab(props) {
  
  const [domains, setDomains] = useState([]);

  const { apiHost, token, actionHandler } = props;
  const [aliases, setAliases] = useState([]);
  const [addAliasModalVisible, setAddAliasModalVisible] = useState(false);
  
  const [email, setEmail] = useState('');
  const [domain, setDomain] = useState('');
  const [destinationEmail, setDestinationEmail] = useState('');

  const [aliasToDelete, setAliasToDelete] = useState({});
  const [deleteAliasModalVisible, setDeleteAliasModalVisible] = useState(false);

  const [aliasToEdit, setAliasToEdit] = useState({});
  const [editAliasModalVisible, setEditAliasModalVisible] = useState(false);
  
  const [loadingModalVisible, setLoadingModalVisible] = useState(false);


  useEffect(() => {
    actionHandler([
      {
        name : 'Nytt alias +', 
        action : showAddAliasModal
      }
    ])
  }, [actionHandler]);

  const loadDomains = useCallback(() => {
    fetch(`${apiHost}/mail/domains`, {
      headers : {
        Authorization : token
      }
    })
    .then(res => res.json())
    .then((domains) => {
      setDomains(domains);
      if(domains.length > 0)
        setDomain(domains[0].domain)
    });
  }, [apiHost, token]);

  const loadAliases = useCallback(() => {
    fetch(`${apiHost}/mail/aliases`, {
      headers : {
        Authorization : token
      }
    })
    .then(res => res.json())
    .then((aliases) => {
      setAliases(aliases);
    });
  }, [apiHost, token]);

  useEffect(() => {
    loadAliases();
    loadDomains();
  }, [loadAliases, loadDomains])

  const hideLoadingModal = () => { setLoadingModalVisible(false); }
  const showLoadingModal = () => { setLoadingModalVisible(true); }

  const reloadAliases = () => {
    loadAliases();
  }

  /** create new alias */

  const showAddAliasModal = () => {
    setDestinationEmail('');
    setEmail('');
    setAddAliasModalVisible(true);
  }

  const closeAddModal = () => {
    setAddAliasModalVisible(false);
  }

  const saveNewAlias = () => {
    closeAddModal();
    showLoadingModal();
    fetch(`${apiHost}/mail/aliases`, {
      method : 'PUT',
      body : JSON.stringify(
        {
          email : `${email}@${domain}`, 
          dest : destinationEmail,
        }
      ),
      headers : {
        Authorization : token
      }
    })
    .then(res => res.json())
    .then(response => {
      hideLoadingModal();
      if(response.createNewAliasReturn) {
        closeAddModal();
        reloadAliases();
      } else {
        alert('Fel när adressen skulle skapas: ' + response);
      }
    });    
  }

  /** edit */

  const showEditModal = (alias) => {
    setAliasToEdit(alias);
    setDestinationEmail(alias.dest)
    setEditAliasModalVisible(true); 
  }

  const closeEditModal = () =>{
    setEditAliasModalVisible(false);
  }

  const saveAlias = () => {
    closeEditModal();
    showLoadingModal();
    fetch(`${apiHost}/mail/aliases/${aliasToEdit.id}`, {
      method : 'POST',
      body : JSON.stringify({ email, dest : destinationEmail }),
      headers : {
        Authorization : token
      }
    })
    .then(res => res.json())
    .then(response => {
      hideLoadingModal();
      if(response.updateAliasReturn) {
        closeEditModal();
        reloadAliases();
      } else {
        alert('Fel när lösenordet skulle ändras: ' + response);
      }
    });  
  }

  /** delete */

  const showDeleteModal = (alias) => {
    setAliasToDelete(alias); 
    setDeleteAliasModalVisible(true);
  }

  const closeDeleteModal = () => {
    setDeleteAliasModalVisible(false);
  }

  const deleteAlias = () => {
    closeDeleteModal();
    showLoadingModal();
    fetch(`${apiHost}/mail/aliases/${aliasToDelete.id}`, {
      method : 'DELETE',
      headers : {
        Authorization : token
      }
    })
    .then(res => res.json())
    .then(response => {
      hideLoadingModal();
      if(response.deleteAccountReturn) {
        closeDeleteModal();
        reloadAliases();
      } else {
        alert('Fel när adressen skulle tas bort: ' + response);
      }
    });  
  }

  return (
    <div>
      {aliases.length === 0 && (
        <div class='listRow'>
          <div class='listCell'>
            Inga e-postalias skapade ännu
          </div>
        </div>
      )}
      {aliases.length > 0 && (
        <div class='listRow'>
          <div class='listCell lc-33 listHead'>
            <strong>E-postadress</strong>
          </div>
          <div class='listCell lc-33 listHead'>
          <strong>Skickas till</strong>
          </div>
          <div class='listCell lc-33'>
            
          </div>
        </div>
      )}
      {aliases.map((alias, i) => {
        return(
          <div class='listRow' key={i}>
            <div class='listCell lc-33'>
              {alias.email}
            </div>
            <div class='listCell lc-33'>
              {alias.dest}
            </div>
            <div class='listCell lc-33'>
              <div class='listActions'>
                <a href="#" onClick={e => { showEditModal(alias); }}>Redigera</a>
                <a href="#" class="red" onClick={e => { showDeleteModal(alias); }}>Ta bort</a>
              </div>
            </div>
          </div>
        )
      })}
      {addAliasModalVisible && (
        <div className="modal">
          <div className="modalContent">
            <label className='splitted'>
              E-postadress:
              <div style={{ display : 'flex', flexDirection : 'row', justifyContent : 'space-between'}}>
                <input type="email" value={email} onChange={e => { setEmail(e.nativeEvent.target.value); }} placeholder="mail" />                
                <select value={domain} onChange={e => { setDomain(e.nativeEvent.target.value); }}>
                  {domains.map((domain, i) => {
                    return(<option key={i} value={domain.domain}>{domain.domain}</option>);
                  })}
                </select>
              </div>
            </label>
            <label>
              Skickas vidare till:
              <div style={{ display : 'flex', flexDirection : 'row', justifyContent : 'space-between'}}>
                <input type="email" value={destinationEmail} onChange={e => { setDestinationEmail(e.nativeEvent.target.value); }} placeholder="mail" />                
              </div>
            </label>         
            <div className='modalButtons'>
              <a href="#" onClick={closeAddModal}>Avbryt</a>
              <a href="#" onClick={saveNewAlias} disabled={!email || !destinationEmail}>Spara</a>
            </div>
          </div>
        </div>
      )}
      {editAliasModalVisible && (
        <div className="modal">
          <div className="modalContent">
            <label>
              Skickas vidare till:
              <div style={{ display : 'flex', flexDirection : 'row', justifyContent : 'space-between'}}>
                <input type="email" value={destinationEmail} onChange={e => { setDestinationEmail(e.nativeEvent.target.value); }} placeholder="mail" />                
              </div>
            </label>
            <div className='modalButtons'>
              <a href="#" onClick={closeEditModal}>Avbryt</a>
              <a href="#" onClick={saveAlias} disabled={!destinationEmail}>Spara</a>
            </div>
          </div>
        </div>
      )}
      {deleteAliasModalVisible && (
        <div className="modal">
          <div className="modalContent">
            <p>Vill du verkligen ta bort aliaset <strong>{aliasToDelete.email}</strong></p>
            <div className='modalButtons'>
              <a href="#" onClick={closeDeleteModal}>Avbryt</a>
              <a href="#" onClick={deleteAlias}>Ta bort</a>
            </div>
          </div>
        </div>
      )}
      {loadingModalVisible && (
        <div className="modal">
          <div className="modalContent">
            <strong>Vänta ...</strong>
          </div>
        </div>
      )}
    </div>
  );
}


export { AliasesTab }